import {
  Box,
  Link,
  Flex,
  useColorModeValue,
  Stack,
  Grid,
  useDisclosure,
  Skeleton,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import MapBox from "@/components/maps/Map";
import WelcomeCard from "../components/dashboard/WelcomeCard";
import Sypher from "../components/dashboard/Sypher";
import NotificationBox from "../components/dashboard/NotificationBox";
import { SearchBar } from "../components/SearchBar";
import CustomActionIconButton from "../components/custom-styled-components/CustomActionIconButton";
import { MdSearch } from "react-icons/md";
import PolicyActions from "../components/dashboard/PolicyActions";
import PublishedDocumentsTable from "../components/dashboard/MostVisitedTable";
import { tableColumnsMostVisited } from "../components/dashboard/components/variables/tableColumnsMostVisited";
import Roles from "../components/dashboard/Roles";
import TasksToBeDone from "../components/dashboard/components/TasksToBeDone";
import DocumentsModal from "../components/dashboard/components/DocumentsModal";
import RoleModal from "../components/dashboard/components/RoleModal";
// import {
//   getEmployeeDashboardData,
//   getSendAccessHistoryByUsername,
// } from "@defense-station/api-service";
import {
  getEmployeeDashboardData,
  listPersonnelDocuments,
  getSendAccessHistoryByUsername,
} from "@defense-station/api-service";
import { showServerError } from "../services/toast-service";
import { useAuth } from "../context/auth";
import { addDays, endOfDay, startOfDay } from "date-fns";
import DocumentViewModal from "../components/dashboard/components/DocumentViewModal";
import Weather from "../components/Weather";

const staticMapData = [
  [76.78890228271484, 30.73390007019043],
  [-79.51280212402344, 43.85029983520508],
];

const HISTORY_TYPE = {
  SEND: "SEND",
  REQUEST: "REQUEST",
  DROPZONE: "DROPZONE",
};

export default function Dashboard() {
  const {
    isOpen: isTasksModalOpen,
    onOpen: onTasksModalOpen,
    onClose: onTasksModalClose,
  } = useDisclosure();
  const {
    isOpen: isDocumentsModalOpen,
    onOpen: onDocumentsModalOpen,
    onClose: onDocumentsModalClose,
  } = useDisclosure();
  const {
    isOpen: isRoleModalOpen,
    onOpen: onRoleModalOpen,
    onClose: onRoleModalClose,
  } = useDisclosure();
  const {
    isOpen: isDocumentViewModalOpen,
    onOpen: onDocumentViewModalOpen,
    onClose: onDocumentViewModalClose,
  } = useDisclosure();
  const { user } = useAuth();
  const [selectedRole, setSelectedRole] = useState();
  const [selectedDocument, setSelectedDocument] = useState();
  const [isLoading, setLoading] = useState(true);
  const [mapData, setMapData] = useState([]);
  const [data, setData] = useState();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [initialized, setInitialized] = useState(false);
  const [isLoadingNext, setLoadingNext] = useState(false);
  const [isLast, setIsLast] = useState(false);
  const [nonCompliantUsers, setNonCompliantUsers] = useState([]);
  const [responseHistoryEnabled, setResponseHistoryEnabled] = useState(true);
  const [dropzoneResponseHistoryEnabled, setDropzoneResponseHistoryEnabled] =
    useState(true);
  const [date, setDate] = useState(new Date());
  const iconBg = useColorModeValue("secondaryGray.300", "navy.700");
  const iconColor = useColorModeValue("brand.500", "white");
  let mainText = useColorModeValue("navy.700", "white");
  let secondaryText = useColorModeValue("gray.700", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const brandColor = useColorModeValue("brand.500", "white");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
    "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
  );
  let menuBg = useColorModeValue("white", "navy.800");

  const renderNotificationBox = (display) => {
    let comp = (
      <Box
        mb={{ base: "20px", lg: "20px" }}
        maxH={{ base: "100%", xl: "40vh", "2xl": "100%" }}
        height={{ base: "max-content", md: "100%" }}
        display={display}
      >
        <NotificationBox
          // hasNext={next || hasMoreDropzoneResponses}
          // fetchNext={fetchNext}
          // loading={isLazyLoading}
          data={[]}
          maxH={{ base: "auto", lg: "100%" }}
          minH={{ base: "auto", lg: "100%" }}
          overflow="hidden"
          _hover={{
            overflow: "auto",
          }}
        />
        {/* <DocSideBar pr={{ base: "auto", lg: "31px" }} maxH={{ base: "auto", lg: "834px" }} minH={{ base: "auto", lg: "834px" }} overflow="hidden" _hover={{
      overflow: "auto",
    }} /> */}
      </Box>
    );
    return comp;
  };

  const onSeeAllTasks = useCallback(() => {
    onTasksModalOpen();
  }, []);

  const onSeeAllDocuments = useCallback(() => {
    onDocumentsModalOpen();
  }, []);

  const onRoleClick = (role) => {
    setSelectedRole(role);
    onRoleModalOpen();
  };

  const scanAndMakeMapData = (data) => {
    let newMapData = [];
    data?.forEach((accessHistory) => {
      newMapData.push([accessHistory?.lon, accessHistory?.lat]);
    });
    let y = Object.values(
      newMapData.reduce((p, c) => ((p[JSON.stringify(c)] = c), p), {})
    );
    setMapData(y);
  };

  const fetchPolicyData = (
    states = {
      resetState: false,
    }
  ) => {
    let args = {
      account_id: user?.account_id,
      username: user?.username,
      personnel_id: user?.email,
      page_size: pageSize,
    };
    if (page) {
      args = { ...args, page_number: states?.resetState ? 1 : page };
    }
    listPersonnelDocuments(args)
      .then((res) => {
        const loadedDocuments = res?.data
          ?.policy_manager_v1_ListPersonnelDocuments?.documents
          ? res?.data?.policy_manager_v1_ListPersonnelDocuments?.documents
          : [];
        if (!loadedDocuments?.length) {
          setIsLast(true);
          return;
        }
        const reversedLoadedDocuments = [...loadedDocuments].reverse();

        setData({
          roles: res?.data?.policy_manager_v1_ListPersonnelRoles?.roles,
          documents:
            data?.documents?.length && !states?.resetState
              ? [...data?.documents, ...reversedLoadedDocuments]
              : reversedLoadedDocuments,
        });
        if (loadedDocuments?.length < pageSize) {
          setIsLast(true);
        }
      })
      .catch(showServerError)
      .finally(() => {
        setLoading(false);
        setInitialized(true);
        setLoadingNext(false);
      });
  };

  useEffect(() => {
    if (user && initialized) {
      setLoadingNext(true);
      fetchPolicyData();
    }
  }, [page, pageSize]);

  const fetchSypherData = () => {
    const baseDate = new Date();
    const startDate = startOfDay(addDays(baseDate, -30));
    const endDate = endOfDay(baseDate);
    getSendAccessHistoryByUsername(
      user?.account_id,
      user?.username,
      startDate,
      endDate
    )
      .then((res) => {
        scanAndMakeMapData(
          res?.data?.sypher_v1_ListAccessHistoryByUsername?.access_histories
        );
        setData((prevData) => ({
          ...prevData,
          notification:
            res?.data?.sypher_v1_ListAccessHistory?.access_histories,
        }));
      })
      .catch(showServerError);
  };

  const init = () => {
    fetchPolicyData();
    fetchSypherData();
  };

  useEffect(() => {
    if (user) {
      init();
    }
  }, [user]);

  const onDocumentView = (document) => {
    setSelectedDocument(document);
    onDocumentViewModalOpen();
  };

  const onAcknowledge = (document) => {
    setData({
      ...data,
      documents: data?.documents?.map((doc) => {
        const newDoc = { ...doc };
        if (doc.document_id == document?.document_id) {
          newDoc.acknowledged = true;
        }
        return newDoc;
      }),
    });
    onDocumentViewModalClose();
  };

  const onNextPage = () => {
    if (user && initialized) {
      setPage(page + 1);
    }
  };

  const documents = useMemo(() => !Array.isArray(data?.documents)?[]:data.documents.slice(0, 8), [data])

  return (
    <>
      <Flex p="6" h="full" maxW="100%" flexDir={"column"}>
        <Flex w="full" justify={"space-between"} align={"center"}>
          {user?.first_name ? (
            <Link
              color={mainText}
              href="#"
              bg="inherit"
              borderRadius="inherit"
              fontWeight="bold"
              fontSize="34px"
              _hover={{ color: { mainText } }}
              _active={{
                bg: "inherit",
                transform: "none",
                borderColor: "transparent",
              }}
              _focus={{
                boxShadow: "none",
              }}
            >
              {`Employee Station`}
            </Link>
          ) : (
            <Skeleton height="20px" w="300px" />
          )}
          <Flex
            w={"35%"}
            alignItems="center"
            flexDirection="row"
            bg={menuBg}
            flexWrap={{ base: "wrap", md: "nowrap" }}
            p="10px"
            borderRadius="30px"
            boxShadow={shadow}
          >
            <SearchBar
              enableLeftIcon
              mb={{ base: "10px", md: "unset" }}
              me="10px"
              // isDisabled={searching}
              // onKeyDown={onEnter}
              borderRadius="30px"
              borderColor="secondaryGray.200"
              placeholder="Ask me anything..."
              // onChange={(e) => setQuestion(e.currentTarget.value)}
            />
            <CustomActionIconButton
              borderRadius="20px"
              variant="outline"
              // isLoading={searching}
              // onClick={fetchAnswer}
              icon={<MdSearch />}
            />
          </Flex>
        </Flex>
        <Grid
          flex={1}
          pt={{ base: "130px", md: "80px", xl: "20px" }}
          mb="20px"
          h="full"
          gridTemplateColumns={{ base: "2.6fr 1fr", "2xl": "3fr 1.3fr" }}
          gap={{ base: "20px", xl: "20px" }}
          display={{ base: "block", lg: "grid" }}
        >
          <Box gridArea="1 / 1 / 2 / 2">
            <Flex gap="20px" mb="20px" flexDir={{ base: "column", xl: "row" }}>
              <Box flex={"1"}>
                <Weather />
              </Box>
              {/* <Box flex={"2"}>
                <PolicyActions onSeeAll={onSeeAllTasks} />
              </Box> */}
              <Stack spacing={"20px"} flex={"1"}>
                {/* <SecurityCard /> */}
                <Sypher />
              </Stack>
            </Flex>
            <Grid
              gridTemplateColumns="1fr 2.6fr"
              gap={{ base: "20px", xl: "20px" }}
              display={{ base: "flex", lg: "grid" }}
              flexDir={"column"}
              mb="20px"
            >
              {/* <Box gridArea="1 / 1 / 2 / 2">
                <Roles
                  isLoading={isLoading}
                  data={data?.roles}
                  onRoleClick={onRoleClick}
                />
              </Box> */}
              <Box gridArea="1 / 1 / 2 / 3">
                <PublishedDocumentsTable
                  onView={onDocumentView}
                  onSeeAllClick={onSeeAllDocuments}
                  isLoading={isLoading}
                  tableData={documents}
                  columnsData={tableColumnsMostVisited}
                  fetchNext={onNextPage}
                  hasNext={!isLast}
                  isLoadingNext={isLoadingNext}
                />
              </Box>
            </Grid>
            <Grid
              mb="20px"
              gridTemplateColumns={{ base: "1fr 1fr", xl: "2fr 1fr" }}
              gap={{ base: "20px", xl: "20px" }}
              display={{ base: "block", lg: "grid" }}
            >
              <Box></Box>
              <Box></Box>
            </Grid>
          </Box>

          <Box gridArea="1 / 2 / 2 / 3">
            <MapBox
              disableMarkers={!mapData?.length}
              style={{
                height: "100%",
                minHeight: "350px",
                borderRadius: "15px",
              }}
              bounds={mapData?.length ? mapData : staticMapData}
            />
          </Box>
        </Grid>
        <TasksToBeDone isOpen={isTasksModalOpen} onClose={onTasksModalClose} />
        <DocumentsModal
          onView={onDocumentView}
          isOpen={isDocumentsModalOpen}
          onClose={onDocumentsModalClose}
          data={data?.documents}
          fetchNext={onNextPage}
          hasNext={!isLast}
          isLoadingNext={isLoadingNext}
        />
        <RoleModal
          data={selectedRole}
          isOpen={isRoleModalOpen}
          onClose={onRoleModalClose}
        />
        {isDocumentViewModalOpen && (
          <DocumentViewModal
            document={selectedDocument}
            isOpen={isDocumentViewModalOpen}
            onClose={onDocumentViewModalClose}
            onAcknowledge={onAcknowledge}
          />
        )}
      </Flex>
    </>
  );
}
